export interface RootState {
  user: User | null;
  csrf: string;
}

export interface User {
  id: string;
  username: string;
  avatar: string;
}

export interface Extension {
  extension_id: string;
  name: string;
}

export interface UsageData {
  api_usage: APIUsageDatum[];
  pub_sub_usage: PubSubUsageDatum[];
  cost: number;
  cost_string: string;
  estimated_cost: number;
  estimated_cost_string: string;
  total_api_usage: number;
  total_pub_sub_usage: number;
  start_timestamp: number;
  end_timestamp: number;
}

export interface APIUsageDatum {
  Timestamp: number;
  Calls: number;
}

export interface PubSubUsageDatum {
  Timestamp: number;
  Messages: number;
}

export enum ExtensionView {
  Manage,
  Analytics
}
