



























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Extension } from '../store/types';
import Media from 'vue-media';

@Component({
  name: 'ExtensionActionMenu',
  components: { Media }
})
export default class ExtensionActionMenu extends Vue {
  @Prop() public extension!: Extension;
  @Prop({ default: true }) public defaultCollapse?: boolean;

  public isActive: boolean = false;

  public created() {
    if (this.defaultCollapse) {
      this.isActive = this.$route.params.extension_id === this.extension.extension_id;
    } else {
      this.isActive = true;
    }
  }

  @Watch('$route.params.extension_id')
  public updateIsActive() {
    this.isActive = this.$route.params.extension_id === this.extension.extension_id;
  }

  public viewExtManager(extensionId: string) {
    this.$router.push({ name: 'manage', params: { extension_id: extensionId } });
  }

  public viewExtAnalytics(extensionId: string) {
    this.$router.push({ name: 'analytics', params: { extension_id: extensionId } });
  }

  public extensionAction(extensionId: string, action: string) {
    if (this.$route.params.extension_id === extensionId && this.$route.name === action) {
      return true;
    } else {
      return false;
    }
  }
}
