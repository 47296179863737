

















































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Extension, ExtensionView } from '../store/types';
import Media from 'vue-media';

import ExtensionManager from '@/views/ExtensionManager.vue';
import ExtensionInfo from '@/views/ExtensionInfo.vue';
import ExtensionActionMenu from '@/components/ExtensionActionMenu.vue';

interface NewExtension {
  name: string;
  extension_id: string;
  extension_secret: string;
}

@Component({
  name: 'Extensions',
  components: { ExtensionManager, ExtensionInfo, ExtensionActionMenu, Media }
})
export default class Extensions extends Vue {
  public get currentExtension() {
    const extensionId = this.$route.params.extension_id;
    let currentExt;

    currentExt = this.ownedExtensions.find((x) => {
      return x.extension_id === extensionId;
    });

    if (!currentExt) {
      currentExt = this.adminExtensions.find((x) => {
        return x.extension_id === extensionId;
      });
    }

    return currentExt;
  }

  public ExtensionView: typeof ExtensionView = ExtensionView;

  public ownedExtensions: Extension[] = [];
  public adminExtensions: Extension[] = [];

  public hideMenu: boolean = true;
  public isSmallDevice: boolean = true;

  public mounted() {
    this.loadExtensions();
  }

  public loadExtensions() {
    this.$api.get(`/api/extensions`).then<any>((response) => {
      this.ownedExtensions = response.data.owned_extensions;
      this.adminExtensions = response.data.admin_extensions;
    });
  }

  public viewExtManager(extensionId: string) {
    this.$router.push({ name: 'manage', params: { extension_id: extensionId } });
  }

  public viewExtAnalytics(extensionId: string) {
    this.$router.push({ name: 'analytics', params: { extension_id: extensionId } });
  }

  public extensionAction(extensionId: string, action: string) {
    if (this.$route.params.extension_id === extensionId && this.$route.name === action) {
      return true;
    } else {
      return false;
    }
  }

  public mediaEnter(mediaQueryString: string) {
    this.hideMenu = true;
    this.isSmallDevice = true;
  }
  public mediaLeave(mediaQueryString: string) {
    this.hideMenu = false;
    this.isSmallDevice = false;
  }

  @Watch('$route')
  public onRouteChanged(to: any, from: any) {
    if (this.isSmallDevice) {
      this.hideMenu = true;
    }
  }
}
